import { AuditForm } from '@/hooks';
import { handleError, httpGet, httpPost } from './util/axios';

export const getAdminUserDetails = async (id: string) => {
  try {
    return httpGet('user/read', `admin/admin-user/${id}/details`);
  } catch (error: any) {
    handleError(error);
  }
};

export const createBlankAdminUser = async () => {
  try {
    return httpPost('user/write', `admin/admin-user/create`);
  } catch (error: any) {
    handleError(error);
  }
};

export const adminResendMail = async (userId: string, year: number) => {
  try {
    return httpPost('user/write', `admin/income/resend-form-emails`, {
      userId,
      year,
    });
  } catch (error: any) {
    handleError(error);
  }
};

export const updateAdminUser = async (id: string, data: any) => {
  try {
    return httpPost('user/write', `admin/admin-user/${id}/update`, data);
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteAdminUser = async (id: string) => {
  try {
    return httpPost('user/write', `admin/admin-user/${id}/delete`, {});
  } catch (error: any) {
    handleError(error);
  }
};

export const registerAuditUser = async (data: AuditForm) => {
  try {
    return httpPost('user/write', `admin/admin-user/register-audit`, data);
  } catch (error: any) {}
};

export const getCurrentUser = async () => {
  try {
    return httpGet('user/read', 'admin/admin-user/current-user');
  } catch (error: any) {}
};

export const getCurrentUserPermissions = async () => {
  try {
    return httpGet('user/read', 'admin/admin-user/current-user-permissions');
  } catch (error: any) {}
};

export const adminConfirmUser = async (userId: string, navigate: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-user/confirm-user', {
      userId,
    });
  } catch (error: any) {
    handleError(error, navigate);
  }
};

export const changeUserPoeStatus = async (
  id: string,
  status: string,
  reason: string,
  rejectTag: string = '',
) => {
  try {
    return httpPost(
      'user/write',
      `admin/mobile-user/${id}/change-user-poe-status`,
      {
        status,
        reason,
        rejectPoeType: rejectTag,
      },
    );
  } catch (error: any) {
    handleError(error);
  }
};

export const changeUserPoeStatusBulk = async (
  userIds: string[],
  status: string,
  reason = '',
  rejectTag: string = '',
) => {
  try {
    return httpPost(
      'user/write',
      `admin/mobile-user/change-multiple-users-poe-status`,
      {
        userIds,
        status,
        reason,
        rejectPoeType: rejectTag,
      },
    );
  } catch (error: any) {
    handleError(error);
  }
};
