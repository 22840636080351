import styled from 'styled-components';
import { Tag } from 'antd';

export const StatusTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CampaignContainer = styled.div``;

export const PageContainer = styled.div`
  .table-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-input {
      width: 350px;
    }
  }
`;
