import { FC, useState, Key, useEffect } from 'react';
import { CommentModal, Container, TableCommentButton } from './styles';
import { Avatar, Button, Drawer, Input, Space, Table, Typography } from 'antd';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { formatISODate } from '@/utils';
import { get } from 'lodash';
import SubmissionDialog from './SubmissionDialog';
import { Brand, ConsumerCompletion } from '@/types';
import ApprovalButtons from './ApprovalButtons';
import ScanStatusTag from './ScanStatusTag';
import { WechatOutlined } from '@ant-design/icons';
import Comments from './SubmissionDialog/Completion/Comments';
import { useConsumerSubmissionDialog } from './SubmissionDialog/dialog-hook';
import { When } from 'react-if';
import BrandPickerModal from '@/components/BrandPickerModal';

type Args = {
  refreshCount: () => void;
};

const ConsumerApproval: FC<Args> = ({ refreshCount }) => {
  const submissionDialog = useConsumerSubmissionDialog();

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  const [status, setStatus] = useState('complete');
  const [textFilter, setTextFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const [commentDialog, setCommentDialog] = useState({
    open: false,
    completionId: '',
  });

  const [brandFilterModal, setBrandFilterModal] = useState({
    open: false,
    brand: null as Brand | null,
    brandId: '',
  });

  const audit = useAudit({
    resourceName: 'consumer-approval-list',
  });

  const { tableProps, refresh } = useTable<ConsumerCompletion>({
    service: 'completion/read',
    path: `admin/consumer/list`,
    params: {
      status,
      filters: {
        text: textFilter,
        brandId: brandFilterModal.brandId,
      },
    },
    /* When updating this, also update the `defaultSortOrder` prop of the
    `Table.Column`s to match this */
    defaultSort: {
      field: 'completedAt',
      order: 'descend',
    },
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [status]);

  useEffect(() => {
    const currentPageCompletionIds =
      tableProps.dataSource?.map((completion) => completion.id) || [];
    submissionDialog.setCurrentPageCompletionIds(currentPageCompletionIds);
    /* Do not include the `submissionDialog` dependency here, as we don't want
    to trigger the effect when its state changes */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableProps.dataSource]);

  return (
    <>
      <Container>
        <div className="table-filter-header">
          <Space>
            <Button
              type={status === 'complete' ? 'primary' : 'default'}
              onClick={() => setStatus('complete')}
            >
              Pending
            </Button>
            <Button
              type={status === 'approved' ? 'primary' : 'default'}
              onClick={() => setStatus('approved')}
            >
              Approved
            </Button>
            <Button
              type={status === 'rejected' ? 'primary' : 'default'}
              onClick={() => setStatus('rejected')}
            >
              Rejected
            </Button>

            <Button
              type={status === 'processing' ? 'primary' : 'default'}
              onClick={() => setStatus('processing')}
            >
              Processing
            </Button>
          </Space>

          <Space>
            <Button
              title="Filter by Brand"
              onClick={() =>
                setBrandFilterModal((prev) => ({ ...prev, open: true }))
              }
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <When condition={!!brandFilterModal.brand}>
                <Space>
                  <Avatar
                    src={brandFilterModal.brand?.logoUrl}
                    size={20}
                    shape="square"
                  />
                  <Typography.Text strong style={{ fontSize: 12 }}>
                    {brandFilterModal.brand?.name}
                  </Typography.Text>

                  <Button
                    type={'link'}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBrandFilterModal((prev) => ({
                        ...prev,
                        brand: null,
                        brandId: '',
                      }));
                    }}
                  >
                    Clear
                  </Button>
                </Space>
              </When>
              <When condition={!brandFilterModal.brand}>Filter by Brand</When>
            </Button>
            <Input.Search
              className="search-input"
              placeholder="Search by campain, user or mission name"
              allowClear
              onSearch={setTextFilter}
            />
          </Space>
        </div>

        <TableContainer>
          <Table
            {...tableProps}
            rowKey={'id'}
            className={'clickable'}
            onRow={(record: ConsumerCompletion) => ({
              onClick: () => submissionDialog.openDialog(record),
            })}
            rowSelection={
              status !== 'complete'
                ? undefined
                : {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                    },
                  }
            }
          >
            <Table.Column
              title="Review Status"
              dataIndex={['scanState']}
              sorter
              render={(_, record: ConsumerCompletion) => {
                return <ScanStatusTag completion={record} />;
              }}
            />

            <Table.Column
              title="Campaign"
              dataIndex={'bundle.name'}
              sorter
              render={(_, record: ConsumerCompletion) =>
                get(
                  record,
                  'bundle.name',
                  <span className="inactive-text">(not set)</span>,
                )
              }
            />

            <Table.Column
              title="Brand"
              dataIndex={'brand.name'}
              sorter
              render={(_, record: ConsumerCompletion) => {
                if (!record.brand) {
                  return <span className="inactive-text">(not set)</span>;
                }

                return (
                  <Space>
                    <Avatar
                      src={record.brand.logoUrl}
                      size={50}
                      shape="square"
                    />
                    <Space.Compact direction={'vertical'}>
                      <Typography.Text strong>
                        {record.brand.name}
                      </Typography.Text>
                    </Space.Compact>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="User Name"
              dataIndex={'consumer.fullName'}
              sorter
              render={(_, record: ConsumerCompletion) =>
                get(
                  record,
                  'consumer.fullName',
                  <span className="inactive-text">(not set)</span>,
                )
              }
            />

            <Table.Column
              title="Email"
              dataIndex={'consumer.email'}
              sorter
              render={(_, record: ConsumerCompletion) =>
                get(
                  record,
                  'consumer.email',
                  <span className="inactive-text">(not set)</span>,
                )
              }
            />

            <Table.Column
              title="Phone number"
              dataIndex={'consumer.phoneNumber'}
              sorter
              render={(_, record: ConsumerCompletion) =>
                get(
                  record,
                  'consumer.phoneNumber',
                  <span className="inactive-text">(not set)</span>,
                )
              }
            />

            <Table.Column
              title="Date & time"
              dataIndex={['completedAt']}
              sorter
              defaultSortOrder="descend"
              render={(value) => formatISODate(value)}
            />

            <Table.Column
              render={(_, record: ConsumerCompletion) => (
                <TableCommentButton
                  type="text"
                  icon={<WechatOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();

                    setCommentDialog({
                      open: true,
                      completionId: record.id,
                    });
                  }}
                />
              )}
            />
          </Table>
        </TableContainer>
      </Container>
      <SubmissionDialog
        onClose={() => submissionDialog.closeDialog()}
        disableApproval={selectedRowKeys.length > 0}
        onRefresh={() => {
          setTimeout(() => refresh(), 100);
        }}
      />

      <BrandPickerModal
        open={brandFilterModal.open}
        onClose={() =>
          setBrandFilterModal((prev) => ({ ...prev, open: false }))
        }
        selected={[brandFilterModal.brandId]}
        multiple={false}
        onSelect={(brandsId, brands) => {
          setBrandFilterModal((prev) => ({
            ...prev,
            brand: brands[0] || null,
            brandId: brandsId[0] || '',
          }));
        }}
        removeSelected={false}
      />

      <CommentModal
        title={'Submission Comments'}
        open={commentDialog.open}
        onCancel={() => setCommentDialog({ open: false, completionId: '' })}
        destroyOnClose
        footer={null}
        width={580}
        centered
      >
        {commentDialog.open && commentDialog.completionId && (
          <Comments completionId={commentDialog.completionId} />
        )}
      </CommentModal>
      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={selectedRowKeys.length > 0}
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>
        <ApprovalButtons
          completionsId={selectedRowKeys as string[]}
          onCompleted={() => {
            refresh();
            refreshCount();
          }}
        />
      </Drawer>
    </>
  );
};

export default ConsumerApproval;
