import { handleError, httpDelete, httpGet, httpPost } from './util/axios';

export const getMobileUserDetails = async (id: string) => {
  try {
    return httpGet('user/read', `admin/mobile-user/${id}/details`);
  } catch (error) {
    handleError(error);
  }
};

export const getPasswordToken = async (id: string) => {
  try {
    return httpGet(
      'user/read',
      `admin/mobile-user/${id}/access-reset-password-token`,
    );
  } catch (error) {
    handleError(error);
  }
};

export const changeStoreRequest = async ({
  requestId,
  status,
  setHighRisk,
}: any) => {
  return httpPost('store/write', 'admin/mobile-user/change-store-request', {
    requestId,
    status,
    setHighRisk,
  });
};

export const changeMultipleStoreRequests = async ({
  requestsId,
  status,
  setHighRisk,
}: any) => {
  return httpPost(
    'user/write',
    'admin/mobile-user/change-multiple-store-requests',
    {
      requestsId,
      status,
      setHighRisk,
    },
  );
};

export const insertWalletAdjustment = async ({
  userId,
  amount,
  annotations,
}: any) => {
  try {
    return httpPost('user-wallet/write', 'admin/insert-adjustment', {
      userId,
      amount,
      annotations,
    });
  } catch (error) {
    handleError(error);
  }
};

export const updateRiskLevel = async ({ userId, riskLevel }: any) => {
  return httpPost(
    'user/write',
    `admin/mobile-user/${userId}/update-risk-level`,
    {
      riskLevel,
    },
  );
};

export const getUserDevices = async (userId: string) => {
  try {
    return httpGet('user/read', `admin/mobile-device/${userId}/devices`);
  } catch (error: any) {
    handleError(error);
  }
};

export const blockDeviceUser = async ({ userId, uid, notes }: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-device/ban', {
      userId,
      uid,
      notes,
    });
  } catch (error) {
    handleError(error);
  }
};

export const unblockDeviceUser = async ({ userId, uid }: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-device/unban', {
      userId,
      uid,
    });
  } catch (error) {
    handleError(error);
  }
};

export const addIntoWhiteListDeviceUser = async ({
  userId,
  uid,
  notes,
}: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-device/add-whitelist', {
      userId,
      uid,
      notes,
    });
  } catch (error) {
    handleError(error);
  }
};

export const removeFromWhiteListDeviceUser = async ({ userId, uid }: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-device/remove-whitelist', {
      userId,
      uid,
    });
  } catch (error) {
    handleError(error);
  }
};

export const addWhitelistPhoneNumberToUser = async ({ userId, notes }: any) => {
  try {
    return httpPost('user/write', `admin/mobile-device/whitelist-phonenumber`, {
      userId,
      notes,
    });
  } catch (error) {
    handleError(error);
  }
};

export const removeWhitelistPhoneNumberToUser = async ({ userId }: any) => {
  try {
    return httpDelete(
      'user/write',
      `admin/mobile-device/${userId}/whitelist-phonenumber`,
    );
  } catch (error) {
    handleError(error);
  }
};

export const adminConfirmUser = async (userId: string, navigate: any) => {
  try {
    return httpPost('user/write', 'admin/mobile-user/confirm-user', {
      userId,
    });
  } catch (error: any) {
    handleError(error, navigate);
  }
};

export const requestPremiumReviewPOE = async () => {
  return await httpPost('user/write', `external/analyze-all-poe-documents`, {});
};

export const getPoeAnalysisPendingProcessingCount = async () => {
  return await httpGet(
    'user/read',
    `admin/mobile-user/poe-analysis-pending-processing-count`,
  );
};

export const getReferralLink = async (userId: string) => {
  try {
    const { referralLink } = await httpGet(
      'user/write',
      `admin/mobile-user/${userId}/request-referral-link`,
    );
    return referralLink;
  } catch (error) {
    return 'Referral Link Generation Error';
  }
};

export const getPossibleUserRoles = async () => {
  try {
    const { roles } = await httpGet(
      'user/read',
      'admin/mobile-user/possible-user-roles',
    );
    return Object.values(roles) as string[];
  } catch (error) {
    handleError(error);
  }
};

export const changeUserRole = async ({
  userId,
  newUserRole,
}: {
  userId: string;
  newUserRole: string;
}) => {
  try {
    const result = await httpPost(
      'user/write',
      'admin/mobile-user/update-user-role',
      { userId, newUserRole },
    );

    return result as { status: true } | { status: false; message: string };
  } catch (error) {
    handleError(error);
  }
};

export const changeUserStore = async (userId: string, newStoreId: string) => {
  const result = (await httpPost(
    'user/write',
    `admin/mobile-user/${userId}/change-user-store`,
    { newStoreId },
  )) as { status: true } | { status: false; message?: string };

  if (!result.status) {
    throw new Error(result.message ?? 'Unknown error');
  }

  return result;
};
