import BrandPickerModal from '@/components/BrandPickerModal';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { notifyRetailers } from '@/services/campaign.service';
import { useSession } from '@/store';
import { TableContainer } from '@/styles';
import { Brand, CampaignListItem } from '@/types';
import { formatISODate } from '@/utils';
import {
  Avatar,
  Button,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Space,
  Table,
  Typography,
} from 'antd';
import { MessageSquareDot } from 'lucide-react';
import { useEffect, useState } from 'react';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { When } from 'react-if';
import TypeTag from './TypeTag';
import { PageContainer } from './styles';
import ApprovalModal from '@/pages/Campaign/components/ApprovalModal';
import {
  TableDateFilterDropdown,
  useTableDateFilterDropdown,
} from '@/components/TableDateFilterDropdown';
import { CopyOutlined } from '@ant-design/icons';
import { DuplicateCampaignModal } from '@/pages/Campaign/components/DuplicateCampaignModal';

const Page = () => {
  const session = useSession();
  const [textFilter, setTextFilter] = useState('');
  const [status, setStatus] = useState('under_review');
  const [brandFilterModal, setBrandFilterModal] = useState({
    open: false,
    brand: null as Brand | null,
    brandId: '',
  });
  const [approvalModalOpen, setApprovalModalOpen] = useState({
    open: false,
    campaignId: '',
  });
  const [duplicateCampaignModalData, setDuplicateCampaignModalData] = useState<{
    bundleId: string;
    brand: {
      id: string;
      name: string;
      logoUrl: string;
    };
  } | null>(null);
  const { dateFilter, dateColumnProps, dateFilterDropdownProps } =
    useTableDateFilterDropdown();

  const [modal, contextHolder] = Modal.useModal();

  const { tableProps, refresh } = useTable<CampaignListItem>({
    service: 'campaign/read',
    path: `admin/list`,
    params: {
      filters: {
        text: textFilter,
        brandId: brandFilterModal.brandId,
        launchDate: dateFilter.value,
      },
      status,
    },
    defaultSort: {
      field: 'launchedAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'campaign',
  });

  const hasApprovalViewPermission = session.hasPermission(
    'campaigns_approval.*',
  );

  useEffect(() => {
    setStatus(!hasApprovalViewPermission ? 'live' : 'under_review');
  }, [hasApprovalViewPermission]);

  useEffect(() => {
    audit.onAccess();
  }, []);

  const getTableMenuItems = (
    campaign: CampaignListItem,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      // {
      //   key: `store-menu-manage-${campaign.id}`,
      //   label: <Link to={`/store/${campaign.id}/manage`}>Manage</Link>,
      //   icon: <SiStackedit size={20} />,
      // },
    ];

    if (
      session.hasPermission('campaigns.write') &&
      ['live', 'finished'].includes(campaign.status)
    ) {
      items.push({
        key: 'duplicate-campaign',
        label: 'Duplicate campaign',
        icon: <CopyOutlined style={{ fontSize: 24 }} />,
        onClick: () => {
          setDuplicateCampaignModalData({
            bundleId: campaign.id,
            brand: campaign.brand,
          });
        },
      });
    }

    if (session.hasPermission('campaigns.edit') && campaign.status === 'live') {
      items.push({
        key: `campaign-send-notification-menu-${campaign.id}`,
        label: 'Send "New Campaign" Notification',
        icon: <MessageSquareDot />,
        onClick: async () => {
          await modal.confirm({
            title: `Confirm Action`,
            content: `
              Are you sure you want to notify users about the campaign "${campaign.name}"?
            `,
            okText: 'Confirm',
            cancelText: 'Cancel',
            async onOk(...args) {
              audit.onClick({
                target: 'Send "New Campaign" Notification',
                data: {
                  campaignId: campaign.id,
                },
              });

              const { status = false } = await notifyRetailers(
                'new_campaign',
                campaign.id,
              );

              if (status) {
                modal.success({
                  title: 'Done!',
                  content: 'The notification was successfully sent.',
                });
              } else {
                modal.error({
                  title: 'Error',
                  content: 'An error occurred while sending the notification.',
                });
              }
            },
          });
        },
      });
    }

    return items;
  };

  return (
    <>
      <Layout>
        <PageHeader noBackButton title="Campaigns" />

        <PageContainer>
          <div className="table-filter-header">
            <Space>
              <Button
                type={status === 'under_review' ? 'primary' : 'default'}
                onClick={() => setStatus('under_review')}
              >
                Under Review
              </Button>

              <Button
                type={status === 'live' ? 'primary' : 'default'}
                onClick={() => setStatus('live')}
              >
                Live
              </Button>
              <Button
                type={status === 'finished' ? 'primary' : 'default'}
                onClick={() => setStatus('finished')}
              >
                Finished
              </Button>

              <Button
                type={status === 'rejected' ? 'primary' : 'default'}
                danger={status === 'rejected'}
                onClick={() => setStatus('rejected')}
              >
                Rejected
              </Button>
            </Space>

            <Space>
              <Button
                title="Filter by Brand"
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <When condition={!!brandFilterModal.brand}>
                  <Space>
                    <Avatar
                      src={brandFilterModal.brand?.logoUrl}
                      size={20}
                      shape="square"
                    />
                    <Typography.Text strong style={{ fontSize: 12 }}>
                      {brandFilterModal.brand?.name}
                    </Typography.Text>

                    <Button
                      type={'link'}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBrandFilterModal((prev) => ({
                          ...prev,
                          brand: null,
                          brandId: '',
                        }));
                      }}
                    >
                      Clear
                    </Button>
                  </Space>
                </When>
                <When condition={!brandFilterModal.brand}>Filter by Brand</When>
              </Button>
              <Input.Search
                className="search-input"
                placeholder="Search by campaign name or mission name"
                allowClear
                onSearch={setTextFilter}
              />
            </Space>
          </div>

          <br />

          <TableContainer>
            <Table
              {...tableProps}
              showHeader={true}
              rowKey={'id'}
              onRow={(record: CampaignListItem) => ({
                onClick: () => {
                  if (record.status === 'under_review') {
                    setApprovalModalOpen({
                      open: true,
                      campaignId: record.id,
                    });
                  }
                },
              })}
              // onChange={(...data) => {
              //   console.log('Table onChange', data);
              // }}
              // expandable={{
              //   rowExpandable: (record: CampaignListItem) => true,
              //   expandedRowRender: (record: CampaignListItem) => {
              //     return <div />;
              //   },
              // }}
            >
              <Table.Column
                title="Campaign Name"
                key="name"
                dataIndex={'name'}
                align="left"
                sorter
                ellipsis
                fixed="left"
              />

              <Table.Column
                title="Type"
                width={150}
                dataIndex={'type'}
                align="center"
                sorter
                ellipsis
                filterMode="tree"
                filters={[
                  {
                    text: 'General',
                    value: 'general',
                  },
                  {
                    text: 'Contest',
                    value: 'contest',
                  },
                  {
                    text: 'Learning',
                    value: 'learning',
                  },
                  {
                    text: 'Consumer',
                    value: 'consumer',
                  },
                ]}
                render={(_, record: CampaignListItem) => {
                  return <TypeTag type={record.type} />;
                }}
              />

              <Table.Column
                title="Brand"
                dataIndex={'brand.name'}
                sorter
                render={(_, record: CampaignListItem) => {
                  if (!record.brand) {
                    return <span className="inactive-text">(not set)</span>;
                  }

                  return (
                    <Space>
                      <Avatar
                        src={record.brand.logoUrl}
                        size={45}
                        shape="square"
                      />
                      <Space.Compact direction={'vertical'}>
                        <Typography.Text strong>
                          {record.brand.name}
                        </Typography.Text>
                      </Space.Compact>
                    </Space>
                  );
                }}
              />

              {['live', 'finished'].includes(status) && (
                <Table.Column
                  title="Users"
                  width={100}
                  key="reach.users"
                  dataIndex={'reach.users'}
                  align="center"
                  sorter
                  render={(_, record: CampaignListItem) => (
                    <Typography.Text strong>
                      {record.reach.users || 0}
                    </Typography.Text>
                  )}
                />
              )}

              {['live', 'finished'].includes(status) && (
                <Table.Column
                  title="Stores"
                  width={100}
                  key="reach.stores"
                  dataIndex={'reach.stores'}
                  align="center"
                  sorter
                  render={(_, record: CampaignListItem) => (
                    <Typography.Text strong>
                      {record.reach.stores || 0}
                    </Typography.Text>
                  )}
                />
              )}

              {['live', 'finished'].includes(status) && (
                <Table.Column
                  title="Launched At"
                  key="launchedAt"
                  dataIndex={'launchedAt'}
                  {...dateColumnProps}
                  sorter
                  filterDropdown={() => (
                    <TableDateFilterDropdown {...dateFilterDropdownProps} />
                  )}
                  render={(_, record: CampaignListItem) =>
                    formatISODate(record.launchedAt)
                  }
                />
              )}

              {status === 'under_review' && (
                <Table.Column
                  title="Country"
                  key="country.name"
                  sorter={false}
                  render={(_, record: CampaignListItem) => record.country?.name}
                />
              )}

              {status === 'under_review' && (
                <Table.Column
                  title="Date & time"
                  key="createdAt"
                  dataIndex={'createdAt'}
                  sorter
                  render={(_, record: CampaignListItem) =>
                    formatISODate(record.createdAt)
                  }
                />
              )}

              {status === 'finished' && (
                <Table.Column
                  title="Finished At"
                  key="finishedAt"
                  sorter
                  render={(_, record: CampaignListItem) =>
                    formatISODate(record.finishedAt)
                  }
                />
              )}

              {['live', 'finished'].includes(status) && (
                <Table.Column
                  title="Actions"
                  dataIndex="actions"
                  key="actions"
                  align="right"
                  fixed="right"
                  width={100}
                  render={(
                    text: any,
                    record: CampaignListItem,
                    index: number,
                  ) => {
                    const items = getTableMenuItems(record);

                    if (items.length === 0) {
                      return null;
                    }

                    return (
                      <Dropdown menu={{ items }}>
                        <Button>
                          <PiDotsThreeOutline size={20} />
                        </Button>
                      </Dropdown>
                    );
                  }}
                />
              )}
            </Table>
          </TableContainer>
        </PageContainer>
      </Layout>

      <BrandPickerModal
        open={brandFilterModal.open}
        onClose={() =>
          setBrandFilterModal((prev) => ({ ...prev, open: false }))
        }
        selected={[brandFilterModal.brandId]}
        multiple={false}
        onSelect={(brandsId, brands) => {
          setBrandFilterModal((prev) => ({
            ...prev,
            brand: brands[0] || null,
            brandId: brandsId[0] || '',
          }));
        }}
        removeSelected={false}
      />

      {contextHolder}

      <ApprovalModal
        open={approvalModalOpen.open}
        campaignId={approvalModalOpen.campaignId}
        onClose={() => setApprovalModalOpen({ open: false, campaignId: '' })}
        onSave={() => {
          refresh();
        }}
      />

      {/* Don't need to refresh the table after duplicating a campaign, as it is
      duplicated as a draft campaign, and draft campaigns are not shown in the
      table */}
      <DuplicateCampaignModal
        data={duplicateCampaignModalData}
        onClose={() => setDuplicateCampaignModalData(null)}
      />
    </>
  );
};

export default Page;
