import { FC, useState, Key, useEffect } from 'react';
import { AdvancedFilterContent, Container } from './styles';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  Input,
  Popover,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { formatISODate, getNameAvatarUrl } from '@/utils';
import { get, values } from 'lodash';
import SubmissionDialog from './SubmissionDialog';
import { Brand, ManagerSubmission } from '@/types';
import { useSubmissionDialog } from './SubmissionDialog/dialog-hook';
import BrandPickerModal from '@/components/BrandPickerModal';
import { When } from 'react-if';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import ApprovalButtons from './ApprovalButtons';
import {
  TableDateFilterDropdown,
  useTableDateFilterDropdown,
} from '@/components/TableDateFilterDropdown';

type Args = {
  refreshCount: () => void;
};

const ManagerSubmissions: FC<Args> = ({ refreshCount }) => {
  const submissionDialog = useSubmissionDialog();
  const [status, setStatus] = useState('pending');

  const [textFilter, setTextFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const [brandFilterModal, setBrandFilterModal] = useState({
    open: false,
    brand: null as Brand | null,
    brandId: '',
  });

  const [advancedFilterModal, setAdvancedFilterModal] = useState({
    open: false,
    active: {
      completionId: '',
      missionId: '',
      campaignId: '',
      userId: '',
      storeId: '',
    },
    temp: {
      completionId: '',
      missionId: '',
      campaignId: '',
      userId: '',
      storeId: '',
    },
  });

  const { dateFilter, dateColumnProps, dateFilterDropdownProps } =
    useTableDateFilterDropdown();

  const audit = useAudit({
    resourceName: 'manager-submissions',
  });

  const { tableProps, refresh } = useTable<ManagerSubmission>({
    service: 'completion/read',
    path: `admin/sales-incentive/manager-submissions`,
    params: {
      status,
      filters: {
        text: textFilter,
        brandId: brandFilterModal.brandId,
        date: dateFilter.value,
        ...advancedFilterModal.active,
      },
    },
    /* When updating this, also update the `defaultSortOrder` prop of the
    `Table.Column`s to match this */
    defaultSort: {
      field: 'submittedAt',
      order: 'descend',
    },
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [status]);

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  useEffect(() => {
    const currentPageCompletionIds =
      tableProps.dataSource?.map((completion) => completion._id) || [];
    submissionDialog.setCurrentPageCompletionIds(currentPageCompletionIds);
    /* Do not include the `submissionDialog` dependency here, as we don't want
    to trigger the effect when its state changes */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableProps.dataSource]);

  return (
    <>
      <Container>
        <div className="table-filter-header">
          <Space>
            <Button
              type={status === 'pending' ? 'primary' : 'default'}
              onClick={() => setStatus('pending')}
            >
              Pending
            </Button>
            <Button
              type={status === 'approved' ? 'primary' : 'default'}
              onClick={() => setStatus('approved')}
            >
              Approved
            </Button>
            <Button
              type={status === 'rejected' ? 'primary' : 'default'}
              onClick={() => setStatus('rejected')}
            >
              Rejected
            </Button>
          </Space>

          <Space>
            <When condition={!!brandFilterModal.brand}>
              <Button
                title="Filter by Brand"
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                icon={!brandFilterModal.brand ? <FilterOutlined /> : undefined}
              >
                <Space>
                  <Avatar
                    src={brandFilterModal.brand?.logoUrl}
                    size={20}
                    shape="square"
                  />
                  <Typography.Text strong style={{ fontSize: 12 }}>
                    {brandFilterModal.brand?.name}
                  </Typography.Text>

                  <Button
                    type={'link'}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBrandFilterModal((prev) => ({
                        ...prev,
                        brand: null,
                        brandId: '',
                      }));
                    }}
                  >
                    Clear
                  </Button>
                </Space>
              </Button>
            </When>
            <When condition={!brandFilterModal.brand}>
              <Button
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                icon={<FilterOutlined />}
              >
                Filter by Brand
              </Button>
            </When>

            <Popover
              content={
                <AdvancedFilterContent>
                  <section>
                    <Typography.Text strong>Completion ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.completionId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            completionId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Campaign ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.campaignId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            campaignId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Mission ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.missionId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            missionId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Client User ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.userId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            userId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <Divider>
                    <PlusOutlined />
                  </Divider>

                  <section>
                    <Typography.Text strong>Store ID</Typography.Text>
                    <Input
                      placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                      value={advancedFilterModal.temp.storeId}
                      onChange={(e) =>
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          temp: {
                            ...prev.temp,
                            storeId: e.target.value,
                          },
                        }))
                      }
                    />
                  </section>

                  <div className="footer">
                    <Button
                      type={'link'}
                      onClick={() => {
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          open: false,
                          temp: {
                            completionId: '',
                            missionId: '',
                            campaignId: '',
                            userId: '',
                            storeId: '',
                          },
                          active: {
                            completionId: '',
                            missionId: '',
                            campaignId: '',
                            userId: '',
                            storeId: '',
                          },
                        }));
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setAdvancedFilterModal((prev) => ({
                          ...prev,
                          open: false,
                          active: { ...prev.temp },
                        }));
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </AdvancedFilterContent>
              }
              trigger="click"
              open={advancedFilterModal.open}
              onOpenChange={(open) => {
                setAdvancedFilterModal((prev) => ({ ...prev, open }));
              }}
            >
              <Badge
                dot={true}
                count={
                  values(advancedFilterModal.active).filter(Boolean).length
                }
              >
                <Button icon={<FilterOutlined />}>Filter by ID's</Button>
              </Badge>
            </Popover>

            <Input.Search
              className="search-input"
              placeholder="Search by campain, user or mission name"
              allowClear
              onSearch={setTextFilter}
            />
          </Space>
        </div>
        <TableContainer>
          <Table
            {...tableProps}
            rowKey={'_id'}
            className={'clickable'}
            onRow={(record: ManagerSubmission) => ({
              onClick: () => submissionDialog.openDialog({ id: record._id }),
            })}
            rowSelection={
              status !== 'pending'
                ? undefined
                : {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                    },
                  }
            }
          >
            <Table.Column
              title="Creator"
              dataIndex="creatorFullName"
              render={(_, record: ManagerSubmission) => (
                <Space>
                  <Avatar
                    src={
                      record.creatorAvatarUrl ||
                      getNameAvatarUrl(record.creatorFullName)
                    }
                    size={60}
                  />
                  <Space.Compact direction={'vertical'}>
                    <Typography.Text strong>
                      {record.creatorFullName}
                    </Typography.Text>
                    <Typography.Text type={'secondary'}>
                      {record.creatorEmail}
                    </Typography.Text>
                    <Typography.Text
                      type={'secondary'}
                      copyable={{
                        tooltips: ['Copy referral code', 'Copied!'],
                        text: record.clientUserId,
                        onCopy: () => {
                          audit.onClick({
                            target: 'copy-clientUserId',
                            data: {
                              clientUserId: record.clientUserId,
                            },
                          });
                        },
                      }}
                    >
                      <code>{record.clientUserId}</code>
                    </Typography.Text>
                  </Space.Compact>
                </Space>
              )}
            />
            <Table.Column
              title="Campaign"
              dataIndex={['missionStatus']}
              filters={[
                { text: 'Live', value: 'live' },
                { text: 'Finished', value: 'archived' },
              ]}
              render={(_, record: ManagerSubmission) => (
                <div>
                  <Typography.Text strong>
                    {record.campaignName}
                    {record.missionStatus === 'live' ? (
                      <span
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          marginLeft: '8px',
                          padding: '0 4px',
                          borderRadius: '2px',
                        }}
                      >
                        Live
                      </span>
                    ) : (
                      ''
                    )}
                  </Typography.Text>
                  <br />
                  <Tag>{record.bundleId}</Tag>
                </div>
              )}
            />

            <Table.Column
              title="Store"
              dataIndex={'storeName'}
              sorter
              render={(_, record: ManagerSubmission) => (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text strong>{record.storeName}</Typography.Text>
                  <Typography.Text type={'secondary'}>
                    {record.storeAddress}
                  </Typography.Text>
                </Space.Compact>
              )}
            />

            <Table.Column
              title="Brand"
              dataIndex={'missionBrandName'}
              sorter
              render={(_, record: ManagerSubmission) => {
                if (!record.missionBrandName) {
                  return <span className="inactive-text">(not set)</span>;
                }

                return (
                  <Space>
                    <Avatar
                      src={record.missionBrandLogoUrl}
                      size={50}
                      shape="square"
                    />
                    <Space.Compact direction={'vertical'}>
                      <Typography.Text strong>
                        {record.missionBrandName}
                      </Typography.Text>
                    </Space.Compact>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Submitted At"
              dataIndex={['submittedAt']}
              {...dateColumnProps}
              sorter
              defaultSortOrder="descend"
              render={(value) => formatISODate(value)}
              filterDropdown={() => (
                <TableDateFilterDropdown {...dateFilterDropdownProps} />
              )}
            />
          </Table>
        </TableContainer>
      </Container>
      <SubmissionDialog
        onClose={() => submissionDialog.closeDialog()}
        disableApproval={selectedRowKeys.length > 0}
        onRefresh={() => {
          setTimeout(() => refresh(), 100);
        }}
      />
      <BrandPickerModal
        open={brandFilterModal.open}
        onClose={() =>
          setBrandFilterModal((prev) => ({ ...prev, open: false }))
        }
        selected={[brandFilterModal.brandId]}
        multiple={false}
        onSelect={(brandsId, brands) => {
          setBrandFilterModal((prev) => ({
            ...prev,
            brand: brands[0] || null,
            brandId: brandsId[0] || '',
          }));
        }}
        removeSelected={false}
      />
      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={selectedRowKeys.length > 0}
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>
        <ApprovalButtons
          completionsId={selectedRowKeys as string[]}
          onCompleted={() => {
            refresh();
            refreshCount();
          }}
        />
      </Drawer>
    </>
  );
};

export default ManagerSubmissions;
