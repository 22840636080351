import { useState, useEffect, useCallback, useMemo } from 'react';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

export function useTableDateFilterDropdown() {
  const [dateFilter, setDateFilter] = useState({
    /** Whether the date filter dropdown is open. */
    isOpen: false,
    /** Whether the date picker is open. Used to prevent closing the filter
     * dropdown when on the date picker. */
    isDatePickerOpen: false,
    /** Temporary value used only to update the active value when closing the
     * filter dropdown by clicking outside. */
    temp: { startDate: '', endDate: '' },
    /** Active value used to filter the table. */
    value: { startDate: '', endDate: '' },
  });

  const isFilterActive = useMemo(
    () => !!dateFilter.value.startDate && !!dateFilter.value.endDate,
    [dateFilter.value.startDate, dateFilter.value.endDate],
  );

  const onFilterDropdownOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        // Always allow opening the filter dropdown
        setDateFilter((prev) => ({
          ...prev,
          isOpen: true,
        }));
      } else if (!dateFilter.isDatePickerOpen) {
        // Only allow closing the filter dropdown if the date picker is not open
        setDateFilter((prev) => ({
          ...prev,
          isOpen: false,
          // Also update the date filter value on close
          value: prev.temp,
        }));
      }
    },
    [dateFilter.isDatePickerOpen],
  );

  const onDatePickerOpenChange = useCallback((open: boolean) => {
    setDateFilter((prev) => ({
      ...prev,
      isDatePickerOpen: open,
    }));
  }, []);

  const onDateFilterChange = useCallback(
    (dateRange: { startDate: string; endDate: string }) => {
      setDateFilter((prev) => ({
        ...prev,
        temp: dateRange,
      }));
    },
    [],
  );

  const onDateFilterApply = useCallback(
    (dateRange: { startDate: string; endDate: string }) => {
      setDateFilter((prev) => ({
        ...prev,
        isOpen: false,
        value: dateRange,
      }));
    },
    [],
  );

  return {
    dateFilter,
    setDateFilter,
    dateColumnProps: {
      filtered: isFilterActive,
      filterDropdownOpen: dateFilter.isOpen,
      onFilterDropdownOpenChange,
    },
    dateFilterDropdownProps: {
      isDatePickerOpen: dateFilter.isDatePickerOpen,
      defaultDate: dateFilter.value,
      onDatePickerOpenChange,
      onChange: onDateFilterChange,
      onApply: onDateFilterApply,
    } satisfies React.ComponentProps<typeof TableDateFilterDropdown>,
  };
}

export function TableDateFilterDropdown({
  isDatePickerOpen,
  defaultDate,
  onDatePickerOpenChange,
  onChange,
  onApply,
}: {
  isDatePickerOpen: boolean;
  /**
   * Optional default date to use when the dropdown is opened.
   *
   * Only necessary when updating the date filter value from outside the
   * component (i.e. outside of the `onApply` callback).
   */
  defaultDate?: { startDate: string; endDate: string };
  onDatePickerOpenChange: (open: boolean) => void;
  /**
   * Called when the date range is changed. Should NOT be used to update the
   * date filter value. For that, use `onApply`.
   *
   * This callback should be used to keep the current internal state of the date
   * filter dropdown to then update the external state when the dropdown is
   * closed without clicking the apply button (e.g. when clicking outside the
   * dropdown).
   */
  onChange: (dateRange: { startDate: string; endDate: string }) => void;
  /** Called when the apply button is clicked. */
  onApply: (dateRange: { startDate: string; endDate: string }) => void;
}) {
  const [dateRange, _setDateRange] = useState(
    defaultDate ?? { startDate: '', endDate: '' },
  );

  function handleChangeDateRange(dateRange: {
    startDate: string;
    endDate: string;
  }) {
    _setDateRange(dateRange);
    onChange(dateRange);
  }

  /* Since the component is not remounted when opening the dropdown (which would
  cause the initial date range to be reset), we need to update the date range
  when the default date changes in an effect */
  useEffect(() => {
    if (defaultDate) {
      _setDateRange(defaultDate);
    }
  }, [defaultDate]);

  return (
    <TableDateFilterContent>
      <div className="title-filter">Filter by date range</div>
      <DatePicker.RangePicker
        open={isDatePickerOpen}
        value={
          dateRange.startDate && dateRange.endDate
            ? [dayjs(dateRange.startDate), dayjs(dateRange.endDate)]
            : undefined
        }
        onOpenChange={onDatePickerOpenChange}
        onChange={(_dates, [startDate, endDate]) => {
          handleChangeDateRange({ startDate, endDate });
        }}
      />

      <div className="actions">
        <Button
          size="small"
          onClick={() => {
            handleChangeDateRange({ startDate: '', endDate: '' });
          }}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            onApply(dateRange);
          }}
        >
          Apply
        </Button>
      </div>
    </TableDateFilterContent>
  );
}

const TableDateFilterContent = styled.div`
  padding: 8px 16px 16px;

  .title-filter {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    column-gap: 8px;

    button {
      flex: 1;
    }
  }
`;
