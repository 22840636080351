import { useMemo, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  notification,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { getStoreDetails } from '@/services/store.service';
import { changeUserStore } from '@/services/mobile-user.service';
import { useAudit, useTable } from '@/hooks';
import type { Store } from '@/types';

import {
  StoreDetails,
  StorePickerPrimitive,
} from '@/components/StoreGroupModalSelector/StorePicker';

type FormData = {
  storeId: string;
};

/**
 * The modal open state can be controlled in two ways:
 * 1. By not providing the `open` prop, the modal will be open if the `userId`
 *    is defined
 * 2. By providing the `open` prop (which requires the `userId` prop to be
 *    defined), the modal will be open if the `open` prop is true
 */
export const ChangeStoreModal = ({
  open,
  userId,
  storeId,
  onChangeStore,
  onCancel,
}: (
  | { open?: never; userId: string | undefined }
  | { open: boolean; userId: string }
) & {
  storeId: string | undefined;
  onChangeStore: () => void;
  onCancel: () => void;
}) => {
  const isModalOpen = useMemo(
    () => open ?? userId !== undefined,
    [open, userId],
  );

  return (
    <Modal
      title="Change User Store"
      open={isModalOpen}
      centered
      footer={null}
      destroyOnClose
      onCancel={onCancel}
    >
      {userId && (
        <ChangeStoreModalContent
          storeId={storeId}
          userId={userId}
          onChangeStore={onChangeStore}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};

function ChangeStoreModalContent({
  storeId,
  userId,
  onChangeStore,
  onCancel,
}: {
  storeId: string | undefined;
  userId: string;
  onChangeStore: () => void;
  onCancel: () => void;
}) {
  const [selectedStore, setSelectedStore] = useState<Store>();

  const { data: currentStore, isLoading: isLoadingCurrentStore } = useQuery({
    queryKey: ['get-store-details', storeId],
    queryFn: async () => {
      if (!storeId) return null;

      const result = await getStoreDetails(storeId);
      if (!result) return null;

      return result.store;
    },
  });

  const [searchText, setSearchText] = useState('');
  const { tableProps } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    perPage: 5,
    params: {
      // Filter out the current store from the list
      selected: [currentStore?.id],
      filters: {
        text: searchText,
        removeSelected: true,
      },
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
    enabled: !isLoadingCurrentStore,
  });

  const audit = useAudit({ resourceName: 'mobile-user' });

  const { mutate: handleChangeStore, isPending: isChangingStore } = useMutation(
    {
      mutationFn: async (values: FormData) => {
        if (!userId) return;
        await changeUserStore(userId, values.storeId);
      },
      onSuccess: (data, variables) => {
        audit.onUpdate({
          target: 'change-store',
          data: {
            userId,
            newStoreId: variables.storeId,
          },
        });
        notification.success({ message: 'User store updated successfully' });

        onChangeStore();
      },
      onError: () => {
        notification.error({ message: 'Failed to update user store' });
      },
    },
  );

  if (isLoadingCurrentStore || !tableProps.dataSource) {
    return <StyledSpin />;
  }

  return (
    <Space style={{ width: '100%' }} direction="vertical" size="middle">
      <Space style={{ width: '100%' }} direction="vertical">
        <Typography.Text style={{ fontSize: 14, lineHeight: '20px' }} strong>
          Current store:
        </Typography.Text>

        {currentStore ? (
          <StoreDetails
            style={{ backgroundColor: '#f5f5f5', width: '100%', padding: 8 }}
            store={currentStore}
          />
        ) : (
          <span>
            This user does not have a valid store associated with their account
          </span>
        )}
      </Space>

      <Space style={{ width: '100%' }} direction="vertical">
        <Typography.Text style={{ fontSize: 14, lineHeight: '20px' }} strong>
          Selected store:
        </Typography.Text>
        {selectedStore ? (
          <StoreDetails
            style={{ backgroundColor: '#f5f5f5', width: '100%', padding: 8 }}
            store={selectedStore}
          />
        ) : (
          <span>No store selected</span>
        )}
      </Space>

      <Input.Search
        placeholder="Search by store name"
        allowClear
        onSearch={(value) => {
          setSearchText(value);
        }}
      />

      <StorePickerPrimitive
        tableProps={tableProps}
        selectedRowKeys={selectedStore?.id}
        setSelectedRowKeys={(_id, store) => {
          setSelectedStore(store);
        }}
      />

      <ModalFooter>
        <Button disabled={isChangingStore} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          type="primary"
          loading={isChangingStore}
          disabled={!selectedStore}
          onClick={() => {
            if (!selectedStore) return;
            handleChangeStore({ storeId: selectedStore.id });
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Space>
  );
}

const StyledSpin = styled(Spin)`
  display: block;
  margin: 1rem auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  column-gap: 8px;
`;
