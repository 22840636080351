import { useId, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Modal,
  notification,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { IoPlanetOutline } from 'react-icons/io5';
import { PiPencilSimpleLine } from 'react-icons/pi';

import { Brand } from '@/types';

import BrandPickerModal from '@/components/BrandPickerModal';
import { duplicateCampaign } from '@/services/campaign.service';
import { useAudit } from '@/hooks';

type BrandData = Pick<Brand, 'id' | 'name' | 'logoUrl'> & {
  isManaged?: boolean;
};

export function DuplicateCampaignModal({
  data,
  onDuplicate,
  onClose,
}: {
  data: {
    bundleId: string;
    brand: BrandData;
  } | null;
  onDuplicate?: (bundleId: string) => void;
  onClose: () => void;
}) {
  const isModalOpen = data !== null;

  return (
    <Modal
      title="Duplicate Campaign"
      open={isModalOpen}
      centered
      footer={null}
      destroyOnClose
      onCancel={onClose}
    >
      {data && (
        <DuplicateCampaignModalContent
          bundleId={data.bundleId}
          fromBrandData={data.brand}
          onDuplicate={onDuplicate}
          onClose={onClose}
        />
      )}
    </Modal>
  );
}

function DuplicateCampaignModalContent({
  bundleId,
  fromBrandData,
  onDuplicate,
  onClose,
}: {
  bundleId: string;
  fromBrandData: BrandData;
  onDuplicate?: (bundleId: string) => void;
  onClose: () => void;
}) {
  const [_toBrandData, setToBrandData] = useState<BrandData | null>(null);

  const copyToSameBrandId = useId();
  const [copyToSameBrand, setCopyToSameBrand] = useState(false);

  const toBrandData = useMemo(() => {
    if (copyToSameBrand) {
      return fromBrandData;
    }
    return _toBrandData;
  }, [copyToSameBrand, fromBrandData, _toBrandData]);

  const audit = useAudit({
    resourceName: 'duplicate-campaign-modal',
  });

  const {
    mutate: _duplicateCampaign,
    isPending: isDuplicating,
    isSuccess: wasDuplicated,
  } = useMutation({
    mutationFn: async ({
      bundleId,
      fromBrandId,
      toBrandId,
    }: {
      bundleId: string;
      fromBrandId: string;
      toBrandId: string;
    }) => {
      const result = await duplicateCampaign({
        bundleId,
        fromBrandId,
        toBrandId,
      });

      if (!result.status) {
        throw new Error('Failed to duplicate campaign');
      }

      return { bundleId: result.bundleId };
    },
    onSuccess: (data, variables) => {
      notification.success({
        message: 'Campaign duplicated',
        description: 'Campaign has been duplicated successfully',
      });
      audit.onCreate({
        target: 'duplicate-campaign',
        data: {
          originalBundleId: variables.bundleId,
          newBundleId: data.bundleId,
          fromBrandId: variables.fromBrandId,
          toBrandId: variables.toBrandId,
        },
      });
      onDuplicate?.(data.bundleId);
      onClose();
    },
    onError: () => {
      notification.error({
        message: 'Failed to duplicate campaign',
        description: 'Please try again later',
      });
    },
  });

  function handleDuplicate() {
    if (!fromBrandData || !toBrandData) return;

    _duplicateCampaign({
      bundleId,
      fromBrandId: fromBrandData.id,
      toBrandId: toBrandData.id,
    });
  }

  return (
    <Space style={{ width: '100%' }} direction="vertical" size="middle">
      <Space style={{ width: '100%' }}>
        <Switch
          id={copyToSameBrandId}
          checked={copyToSameBrand}
          disabled={isDuplicating || wasDuplicated}
          onChange={setCopyToSameBrand}
        />
        <label htmlFor={copyToSameBrandId}>
          Copy campaign to the same brand
        </label>
      </Space>

      <Space style={{ width: '100%' }} direction="vertical">
        <Typography.Text style={{ fontSize: 14, lineHeight: '20px' }} strong>
          From brand:
        </Typography.Text>
        <SelectedBrand brandData={fromBrandData} />
      </Space>

      {!copyToSameBrand && (
        <BrandSelect
          brandData={toBrandData}
          disabled={isDuplicating || wasDuplicated}
          onChange={(brandData) => setToBrandData(brandData)}
        />
      )}

      <ModalFooter>
        <Button disabled={isDuplicating || wasDuplicated} onClick={onClose}>
          Cancel
        </Button>

        <Button
          type="primary"
          loading={isDuplicating || wasDuplicated}
          disabled={!fromBrandData || !toBrandData}
          onClick={handleDuplicate}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Space>
  );
}

function SelectedBrand({
  children,
  brandData,
}: {
  children?: React.ReactNode;
  brandData: BrandData;
}) {
  return (
    <SelectedBrandContainer>
      <Avatar
        src={brandData.logoUrl}
        style={{ marginRight: 10 }}
        size={44}
        shape="square"
        icon={<IoPlanetOutline />}
      />

      <Space.Compact
        direction="vertical"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography.Text
          className="name"
          ellipsis
          strong
          style={{ fontSize: 16, lineHeight: '22px' }}
        >
          {brandData.name}
        </Typography.Text>

        {typeof brandData.isManaged !==
        'boolean' ? null : brandData.isManaged ? (
          <Tag
            color="#ff8000"
            style={{
              fontSize: 10,
              fontWeight: 300,
              padding: '0 4px',
              borderRadius: 4,
            }}
          >
            Retailer
          </Tag>
        ) : (
          <Tag
            color="purple"
            style={{
              fontSize: 10,
              fontWeight: 300,
              padding: '0 4px',
              borderRadius: 4,
            }}
          >
            Brand
          </Tag>
        )}
      </Space.Compact>

      {children}
    </SelectedBrandContainer>
  );
}

function BrandSelect({
  brandData,
  disabled,
  onChange,
}: {
  brandData: BrandData | null;
  disabled?: boolean;
  onChange: (brandData: BrandData | null) => void;
}) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Typography.Text style={{ fontSize: 14, lineHeight: '20px' }} strong>
        To brand:
      </Typography.Text>

      {brandData ? (
        <SelectedBrand brandData={brandData}>
          <Tooltip title="Change brand">
            <Button
              style={{ marginLeft: 'auto' }}
              disabled={disabled}
              onClick={() => setIsPickerOpen(true)}
            >
              <PiPencilSimpleLine size={16} />
            </Button>
          </Tooltip>
        </SelectedBrand>
      ) : (
        <Button disabled={disabled} onClick={() => setIsPickerOpen(true)}>
          Select brand
        </Button>
      )}

      <BrandPickerModal
        open={isPickerOpen}
        selected={brandData ? [brandData.id] : []}
        onSelect={(_brandIds, brands) => {
          const brand = brands[0];
          if (!brand) return;

          onChange({
            id: brand.id,
            name: brand.name,
            logoUrl: brand.logoUrl,
            isManaged: brand.isManaged,
          });
        }}
        onClose={() => setIsPickerOpen(false)}
      />
    </Space>
  );
}

const SelectedBrandContainer = styled(Space.Compact)`
  background-color: #f5f5f5;
  align-items: center;
  width: 100%;
  padding: 8px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  column-gap: 8px;
`;
